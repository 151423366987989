<template>
    <div class="main">
        <div class="content-box">
            <div class="main-video-box">
                <div class="video-title">{{nowData.title}}</div>
                <div class="video-info">
                    <video :src="nowData.video_url" width="100%" height="200px" :poster="nowData.cover_images" controls="controls">
                    您的浏览器不支持 video 标签。
                    </video>
                </div>
            </div>
            <div>
                <div class="item-title">推荐</div>
                <div @click="downloadApp" class="main-video-box">
                    <div class="video-title">{{nowData.recommend_title}}</div>
                    <div class="video-info" style="z-index: -1">
                        <video :src="nowData.recommend_video_url" width="100%" height="200px" :poster="nowData.recommend_cover_images" controls="controls">
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                </div>
            </div>
            <div class="bottom-button"></div>
        </div>
        <div @click="downloadApp" class="bottom-blank">Andriod下载</div>
        <!--分享遮罩-->
        <div v-if="showShare" @click="showShare = false" class="share-box">
            <img src="../../assets/image/to-share.png">
            <div class="share-text">请在浏览器打开</div>
        </div>
    </div>
</template>

<script>
    import { getShareInfo } from '@/api/app'
    import { Toast } from 'vant';
    export default {
        name: 'Show',
        components: {
        },
        data() {
            return {
                userId: '', // 用户id
                nowUserInfo: {}, // 当前用户信息
                query: '',
                nowData: '',
                phoneType: '', // 手机类型 1 安卓 2 苹果
                isWeiXin: '',
                showShare: false
            }},
        created() {
            let query=this.$route.query;
            if(!query.id){
                Toast('参数错误')
                return
            }
            this.query = query
            this.getAllParam()
            this.checkPhone()
            this.checkWeiXin()
        },
        mounted:function(){
        },
        methods: {
            // 获取详情
            getAllParam() {
                const postData = {}
                postData.id = this.query.id
                getShareInfo(postData).then((response) => {
                    console.log(response)
                    this.nowData = response.data
                })
            },
            /* 判断用户手机为安卓还是iphone */
            checkPhone () {
                let self = this
                let agent = (navigator.userAgent || navigator.vendor || window.opera)
                if (agent != null) {
                    let agentName = agent.toLowerCase()
                    if (/android/i.test(agentName)) {
                        self.phoneType = 1
                    } else if (/iphone/i.test(agentName)) {
                        self.phoneType = 2
                    }
                }
            },
            // 判断是不是在微信环境
            checkWeiXin (){
                this.isWeiXin = navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 ? true : false
            },
            // 下载 App
            downloadApp(){
                if(this.phoneType === 2) {
                    Toast('暂不支持iPhone手机下载')
                    return
                }
                if(this.isWeiXin){
                    this.showShare = true
                    return
                }
                window.location.href = this.nowData.appDownUrl
            },
            // 隐藏分享页面
            hideShare(){
                this.showShare = false
            },
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        position: relative;
    }
    .content-box{
        padding: 15px;
    }
    .bottom-blank{
        width: 100%;
        height: 30px;
    }
    .video-title{
        height: 31px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 31px;
    }
    .item-title{
        font-size: 21px;
        font-weight: bold;
        height: 50px;
        line-height: 50px;
    }
    .bottom-blank{
        position: fixed;
        bottom: 20px;
        left: 20px;
        width: calc(100vw - 40px);
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        font-size: 18px;
        color: #333333;
        border: 1px solid #CCCCCC;
    }
    .share-box{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .share-box img{
        width: 67px;
        height: 112px;
        position: absolute;
        top: 0;
        right: 35px;
    }
    .share-text{
        position: absolute;
        top: 132px;
        right: 35px;
        color: #ffffff;
        font-size: 23px;
    }
</style>
