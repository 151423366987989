import request from '@/utils/request'
// const baseUrl = 'http://192.168.1.91:56666/api'
const baseUrl = 'https://state.renruikeji.cn/api'
// 获取分享信息
export function getShareInfo(data) {
  return request({
    url: '/rkktapp/video/getShareArticle',
    method: 'get',
    params: data,
    baseURL: baseUrl
  })
}


